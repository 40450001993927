import { ReactNode, FC } from 'react';

interface PropertyLayoutProps {
  label: string;
  description: string;
  children?: ReactNode;
}

const PropertyLayout: FC<PropertyLayoutProps> = ({ label, description, children }) => {
  return (
    <div className="mt-4">
      <div>
        <span className="text-black">{label}</span>
      </div>
      <div className="text-color-on-first-primary-1 text-dpm-14 truncate">{description}</div>
      <div className="mt-2 flex items-center gap-2">{children}</div>
    </div>
  );
};

export default PropertyLayout;
